import { openMsgModal } from '@/common/Common.js'
import TariffService from './TariffService'
import { MSG_TYPE } from '@/common/const.js'

export function search(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  obj.searchCondition.isFromModal = '1npm'
  TariffService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  obj.tableItems = []
}
